import React, { useState } from 'react';
import { Link } from 'gatsby';
import { FaGithub, FaSlackHash, FaTwitter, FaYoutube } from 'react-icons/fa';

import '../styles/footer.scss';

const Footer = () => {
    return (
        <footer>
            <div className="footer-section">
                <div className="footer-nav">
                    <div className="branding">
                        <Link to="/" className="footer-logo">
                            <img
                                src="/form.svg"
                                alt="Form with Sheet"
                                height="30px"
                            />
                        </Link>
                    </div>
                    <div className="footer-main">
                        <div md={20} className="linking">
                            <ul>
                                <li>
                                    <span>Flows</span>
                                </li>
                                <li>
                                    <Link
                                        to="/forms/google-sheet"
                                        title="XML to Google Sheet"
                                    >
                                        Form with Google Sheet
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/" title="XML to Google Sheet">
                                        Form with Airtable
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/" title="XML to Google Sheet">
                                        Form with Supabase
                                    </Link>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <span>Resources</span>
                                </li>
                                <li>
                                    <Link to="/forms" title="Marketplace">
                                        View all forms
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/pricing" title="Marketplace">
                                        Pricing
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link to="/tutorials">Tutorials</Link>
                                </li> */}
                                <li>
                                    <Link to="/contact">Contact</Link>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <span>Company</span>
                                </li>
                                <li>
                                    <a
                                        href="https://headwayapp.co/formwithsheet-changelog"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Form with Sheet Changelogs"
                                    >
                                        Changelogs
                                    </a>
                                </li>
                                <li>
                                    <Link
                                        to="/privacy-policy"
                                        title="Privacy Policy"
                                    >
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/terms" title="Terms">
                                        Terms
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="bottom-links">
                    <div className="col-md-12" style={{ fontSize: 14 }}>
                        © 2022 All rights reserved.
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
