import React from 'react';
import { Link } from 'gatsby';
import { Button } from 'antd';

import AuthModal from '../authModal';

import '../../styles/home.scss';
import { MdOutlineDynamicForm } from 'react-icons/md';
import Footer from '../footer';

const HomeLayout = ({ children, isLogged }) => {
    return (
        <div className="home-layout">
            <div className="home-layout-inner">
                <div className="home-head-nav">
                    <Link to="/" className="logo" style={{ color: '#000' }}>
                        <img src="/form.svg" height="40px" />
                        <span>Form Sheet</span>
                    </Link>
                    <div className="nav-menu">
                        <ul>
                            <li>
                                <Link to="/pricing">Pricing</Link>
                            </li>
                            <li>
                                <Link to="/login">Login</Link>
                            </li>
                            {isLogged ? (
                                <li className="view-forms">
                                    <Link to="/forms">
                                        <Button
                                            type="primary"
                                            icon={<MdOutlineDynamicForm />}
                                        >
                                            view all forms
                                        </Button>
                                    </Link>
                                </li>
                            ) : (
                                <li className="view-forms">
                                    <Link to="/signup">
                                        <Button type="primary">
                                            Get started — it's free
                                        </Button>
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            <div>{children}</div>
            <Footer />
        </div>
    );
};

export default HomeLayout;
